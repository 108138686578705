import React from 'react'
import { Text5 } from '../Typography'
import ArrowLink from '../ArrowLink'

interface IProps {
	title: string
	href: string
}

export const ExternalLink = ({ title, href }: IProps) => {
	return (
		<ArrowLink rel="noopener noreferrer" color="black" href={href} target="_blank" pr={2}>
			<Text5>{title}</Text5>
		</ArrowLink>
	)
}
