import React from 'react'
import { getResponsiveImagePath } from '../ResponsiveImage'
import theme from '../../styles/theme'
import { Default as DefaultLogo } from '../Logo'
import { Text2 } from '../Typography'
import LocationSearch from '../LocationSearch'
import { Box, Grid } from '@mui/material'
import { NoSsr } from '@mui/base'

export const HomepageHeader = () => {
	return (
		<Grid container>
			<Grid item width={[0, 0, 6 / 12, 6 / 12]}>
				<Box
					bgcolor={theme.colors.deepGreen90}
					sx={{
						height: '100%',
						backgroundImage: `url(${getResponsiveImagePath({
							width: 500,
							height: 500,
							path: 'v1606119991/fp_header_vfgtwk',
						})})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'top center',
					}}
				/>
			</Grid>

			<Grid item width={[1, 1, 6 / 12, 6 / 12]}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-evenly"
					flexDirection="column"
					bgcolor={theme.colors.deepGreen90}
					py={[2, 2, 3, 5]}
					px={[2, 3, 3, 5]}
				>
					<Box width={[0, 0, 1, 1]} mb={4}>
						<DefaultLogo display="block" color1="#FFF" color2="#068751" color3="#FFF" />
					</Box>

					<Box width={[1, 1, 1, 10 / 12]} mb={4}>
						<Text2 color="white" as="h1" fontWeight="700" textAlign="center">
							Ferske råvarer, direkte fra din lokale tilbyder
						</Text2>
					</Box>

					<Box>
						<NoSsr>
							<LocationSearch />
						</NoSsr>
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}
