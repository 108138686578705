import styled from 'styled-components'

const Line = styled.hr`
	display: block;
	border-top: 1px solid #b2b2b2;
	margin: 4em 0;
	@media screen and (max-width: 64em) {
		margin: 2em 0;
	}
	@media screen and (max-width: 40em) {
		margin: 1em 0;
	}
`

const Devider = () => {
	return <Line />
}
export default Devider
