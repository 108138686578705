import React, { useState, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Button, Flex } from 'rebass/styled-components'
import styled from 'styled-components'
import { AppContext } from '../../context/store/context'
import Box from '@mui/material/Box'
import { LocationSearchBasic } from './basic'

const LocationSearch = () => {
	const {
		state: { location },
	} = useContext(AppContext)

	const [address, setAddress] = useState<string>(null)
	const [searchError, setSearchError] = useState(null)
	const router = useRouter()
	const isFrontpage = router.route === '/'

	useEffect(() => {
		if (location && location.googlePlacesValue) {
			setAddress(location.googlePlacesValue)
		}
	}, [location])

	// TODO: Problem is related to search button
	// No sense to have this button here! Must be exported and used if the location context is available!
	const SearchBtn = () => {
		return (
			<Btn
				variant={searchError ? 'error' : 'primary'}
				fontWeight="regular"
				disabled={address === null}
				onClick={() => {
					if (address) {
						router.push('/produkter')
					} else {
						setSearchError({ text: 'Skriv inn din adresse' })
					}
				}}
			>
				{searchError ? searchError.text : 'Finn lokale produkter'}
			</Btn>
		)
	}

	return (
		<Flex
			width={1}
			maxWidth="600px"
			height="100%"
			justifyContent="center"
			flexWrap="wrap"
			alignItems="stretch"
			margin="auto"
		>
			<Box
				sx={{
					width: '320px',
				}}
			>
				<LocationSearchBasic />
			</Box>

			{isFrontpage ? <SearchBtn /> : null}
		</Flex>
	)
}

const Btn = styled(Button)`
	border-radius: 4px;
	width: 320px !important;
	height: 45px;
	margin-top: 16px;
	cursor: pointer;
`

export default LocationSearch
