import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/store/context'
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-google-places-autocomplete'

export const LocationSearchBasic = () => {
	const {
		state: { location },
		dispatch,
	} = useContext(AppContext)

	const [address, setAddress] = useState<string>(null)

	useEffect(() => {
		if (location) {
			if (location.googlePlacesValue) {
				setAddress(location.googlePlacesValue)
			} else {
				// Fix legacy bug where old filters are still saved into users browsers.
				// We have to reset it.
				dispatch({ type: 'REMOVE_LOCATION' })
			}
		}
	}, [location])

	return (
		<GooglePlacesAutocomplete
			apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}
			apiOptions={{ language: 'no', region: 'no' }}
			autocompletionRequest={{
				componentRestrictions: {
					country: ['no'],
				},
			}}
			selectProps={{
				placeholder: 'Legg til din posisjon',
				value: address,
				noOptionsMessage: () => 'Skriv din adresse eller by',
				isClearable: true,
				onChange: async (v) => {
					if (!v) {
						setAddress(null)
						dispatch({ type: 'REMOVE_LOCATION' })
						return
					}

					const results = await geocodeByAddress(v.label)
					const coordinates = await getLatLng(results[0])
					setAddress(v.label)

					const userLocation = {
						latitude: coordinates.lat,
						longitude: coordinates.lng,
						address: v.label,
						googlePlacesValue: v,
					}

					setAddress(v)
					dispatch({ type: 'UPDATE_LOCATION', payload: userLocation })
				},
			}}
		/>
	)
}
