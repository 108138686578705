import React from 'react'
import theme from '../../styles/theme'
import { Text3, Text6 } from '../Typography'
import { ExternalLink } from '../Link/Link'
import { Box, Grid } from '@mui/material'

interface IProps {
	img: string
	title: string
	url: string
	excerpt: string
}

export const BlogFeaturedArticle = ({ img, title, url, excerpt }: IProps) => {
	return (
		<Grid container sx={{ borderRadius: 1, overflow: 'hidden' }} bgcolor={theme.colors.yellow50}>
			<Grid item width={[1, 1, 6 / 12, 6 / 12]} minHeight={200}>
				<Box
					sx={{
						height: '100%',
						backgroundImage: `url(${img})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				/>
			</Grid>

			<Grid item width={[1, 1, 6 / 12, 6 / 12]} px={[2, 3, 4, 5]} py={2}>
				<Text3 py={[3, 1, 2, 3]} textAlign="left" fontWeight="medium">
					{title}
				</Text3>
				<Text6 mb={3} textAlign={'justify'} fontWeight="regular">
					{excerpt}
				</Text6>
				<Box display={'flex'} justifyContent={'right'} pb={2}>
					<ExternalLink href={url} title={'Les mer'} />
				</Box>
			</Grid>
		</Grid>
	)
}
