import React from 'react'
import NextLink from 'next/link'
import { Text4, Text5 } from '../Typography'
import ArrowLink from '../ArrowLink'
import ProductPreview from '../ProductPreview'
import { Box, Grid } from '@mui/material'
import { ProductPreview as ProductPreviewGQL } from '../../apis/generated/graphql'

interface IProps {
	productsPreview: ProductPreviewGQL[]
}

export const HomepageProducts = ({ productsPreview }: IProps) => {
	return (
		<Box>
			<Box display="flex" py={2} justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Text4 fontWeight="semibold">Våre produkter</Text4>
				<NextLink href="/produkter" passHref>
					<ArrowLink my={2}>
						<Text5>Se alle produkter</Text5>
					</ArrowLink>
				</NextLink>
			</Box>

			<Grid container spacing={2}>
				{productsPreview.map((product) => {
					return (
						<Grid item width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} key={product.id} mb={4}>
							<ProductPreview product={product} />
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
}
