import React from 'react'
import { Flex, Box } from 'rebass/styled-components'
import styled from 'styled-components'
import { Text6, Text5, Text7 } from '../Typography'
import NextLink from 'next/link'
import ResponsiveImage, { BasicImage } from '../ResponsiveImage'
import Price from '../Price'
import { ProductPreview as ProductPreviewGQL } from '../../apis/generated/graphql'

interface IProps {
	product: ProductPreviewGQL
}

const ProductPreview = (props: IProps) => {
	const {
		product: {
			slug,
			title,
			imgId,
			cost,
			unitsPerProduct,
			unitType,
			merchant: { slug: merchantSlug },
		},
	} = props

	const costPerUnit = cost / unitsPerProduct

	return (
		<Box style={{ position: 'relative' }}>
			<NextLink href="/[produsent]/[slug]" as={`/${merchantSlug}/${slug}`} passHref>
				<StyledCard as="a">
					<BasicImage width={250} height={250} path={imgId} alt={title} />
					<Flex py={3} alignItems="center" justifyContent="space-between">
						<Box width="100%">
							<Flex width="100%" justifyContent="space-between" alignItems="center" flexWrap="wrap">
								<Text5
									m={0}
									width="100%"
									fontWeight="700"
									lineHeight="22px"
									css={{ wordWrap: 'anywhere' }}
								>
									{title}
								</Text5>
							</Flex>
							<Flex flexWrap="wrap" alignItems="center">
								<Text6 m={0} fontWeight="medium" pr={2}>
									<Price value={cost} />
								</Text6>
								<Text7 style={{ color: '#666666' }} m={0}>
									<Price value={costPerUnit} /> / {unitType}
								</Text7>
							</Flex>
						</Box>
					</Flex>
				</StyledCard>
			</NextLink>
		</Box>
	)
}

const StyledCard = styled(Box)`
	display: block;
	text-decoration: none;
	color: black;
	transition: box-shadow 0.2s ease;
	outline: 0;

	.product__image {
		position: relative;
		overflow: hidden;
	}

	figure {
		transform-origin: center center;
		background: #fff;
		transition:
			transform 0.3s ease,
			border-radius 0.2s ease-in-out;
	}

	.overlay {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.2);
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
	}

	&:hover,
	&:focus {
		.overlay {
			opacity: 0.8;
		}
	}
`

export default ProductPreview
