import React from 'react'
import NextLink from 'next/link'
import styled from 'styled-components'
import { Col } from '../components/Grid'
import { Text2, Text5, Text6 } from '../components/Typography'
import { BasicImage } from '../components/ResponsiveImage'
import Devider from '../components/Devider'
import ArrowLink from '../components/ArrowLink'
import { getPosts, getSinglePost } from '../components/Posts'
import {
	MerchantPreview,
	ProductPreview as ProductPreviewGQL,
	ReadMerchantsPreviewQuery,
	ReadMerchantsPreviewQueryVariables,
	ReadProductsPreviewQuery,
	ReadProductsPreviewQueryVariables,
} from '../apis/generated/graphql'
import { addApolloState, initializeApolloForSSR } from '../lib/apolloClient'
import { ApolloError } from '@apollo/client'
import { ReadMerchantsPreview } from '../apis/merchants'
import { ReadProductsPreview } from '../apis/product'
import Merchant from '../components/Illustrations/Merchant'
import { Food } from '../components/Illustrations'
import { Box, Button, Container } from '@mui/material'
import theme from '../styles/theme'
import { HomepageHeader } from '../components/General/HomepageHeader'
import { HomepageProducts } from '../components/General/HomepageProducts'
import { HomepageBlog } from '../components/General/HomepageBlog'
import { apolloUrl, frontendUrl } from '../lib/url'
import { HomepageMerchants } from '../components/General/HomepageMerchants'

export const MerchantDesc = styled(Text6)`
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`

interface IProps {
	merchantsPreview: MerchantPreview[]
	merchantsPreviewError: string | null
	productsPreview: ProductPreviewGQL[]
	productsPreviewError: string | null
	blogPosts: any[]
	featuredBlogPost: any
}

const Homepage = (props: IProps) => {
	const {
		merchantsPreview,
		merchantsPreviewError,
		productsPreview,
		productsPreviewError,
		blogPosts,
		featuredBlogPost,
	} = props

	return (
		<Container sx={{ position: 'relative' }}>
			<HomepageHeader />
			<HomepageProducts productsPreview={productsPreview} />
			<Devider />
			<HomepageBlog blogPosts={blogPosts} featuredBlogPost={featuredBlogPost} />
			<Devider />
			<HomepageMerchants merchantsPreview={merchantsPreview} />
			<Devider />
			<Box
				display="flex"
				mx="auto"
				width={[1, 1 / 3, 1 / 3, 1 / 3]}
				justifyContent="space-evenly"
				alignItems="center"
				flexWrap="wrap"
				my="4"
			>
				<Box>
					<Merchant />
				</Box>
				<Box>
					<svg
						width="48"
						height="34"
						viewBox="0 0 48 34"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M30.0601 31.9187L45.5987 17.674C45.9176 17.3817 45.9155 16.8784 45.5942 16.5888L38.1267 9.85723L30.0601 2.58557"
							stroke="#FFC350"
							strokeWidth="3.95503"
							strokeLinecap="round"
						/>
						<path
							d="M41.7931 17.2515H2.19336"
							stroke="#FFC350"
							strokeWidth="3.95503"
							strokeLinecap="round"
						/>
					</svg>
				</Box>
				<Box>
					<Food />
				</Box>
			</Box>
			<Box
				display="flex"
				mx="auto"
				width={[1, 2 / 3, 2 / 3, 2 / 3]}
				justifyContent="space-evenly"
				alignItems="center"
				flexWrap="wrap"
				my="4"
			>
				<Text2 textAlign="center">
					Vi minimerer <strong>mellomleddene</strong>, slik at bonden tjener mer og du får ferskere
					råvarer.
				</Text2>
				<NextLink href="/om-oss" passHref>
					<ArrowLink my="4">
						<Text5 variant="underline">Les mer om hvordan</Text5>
					</ArrowLink>
				</NextLink>
			</Box>
			<Box
				display="flex"
				width={[1, 1, 1, 1]}
				bgcolor={theme.colors.deepGreen10}
				justifyContent="space-evenly"
				alignItems="center"
				flexWrap="wrap"
				mb={[4, 4, 5, 5]}
			>
				<Box width={[1, 1, 1 / 2, 1 / 2]} p="0" mr="auto">
					<BasicImage width={800} height={600} path="v1571054281/matfra-forside-bilde" />
				</Box>
				<Col width={[1, 1, 5 / 12, 5 / 12]} mx="auto">
					<Text2
						css={{
							'@media screen and (max-width: 63em)': {
								fontSize: '24px',
								lineHeight: '36px',
							},
						}}
						py={[3, 3, 3, 4]}
						px="4"
						textAlign="center"
						fontWeight="medium"
					>
						Selg dine produkter på Matfra.no
					</Text2>
					<Text5 mb={3} textAlign="center" fontWeight="regular">
						Vi har laget en skreddersydd markedsplass som er et effektivt salgsverktøy for å enkelt
						kunne selge produktene dine direkte.
					</Text5>
					<Box display="flex" alignItems="center" flexDirection="column">
						<NextLink href="/bli-produsent">
							<Button
								variant="contained"
								sx={{
									padding: 2,
									fontSize: 15,
									marginTop: 1,
									marginBottom: 3,
								}}
							>
								Få egen nettbutikk
							</Button>
						</NextLink>
					</Box>
				</Col>
			</Box>
		</Container>
	)
}

export async function getServerSideProps(context) {
	const apolloClient = initializeApolloForSSR(apolloUrl)

	// Get some merchants to show
	let merchantsPreview: MerchantPreview[] = []
	let merchantsPreviewError: string | null = null
	try {
		const { data } = await apolloClient.query<
			ReadMerchantsPreviewQuery,
			ReadMerchantsPreviewQueryVariables
		>({
			query: ReadMerchantsPreview,
			variables: {
				limit: 3,
			},
		})
		merchantsPreview = data.merchants as MerchantPreview[]
	} catch (e) {
		merchantsPreviewError = (e as ApolloError).message
	}

	// Get some products to show
	let productsPreview: ProductPreviewGQL[] = []
	let productsPreviewError: string | null = null
	try {
		const { data } = await apolloClient.query<
			ReadProductsPreviewQuery,
			ReadProductsPreviewQueryVariables
		>({
			query: ReadProductsPreview,
			variables: {
				limit: 4,
			},
		})
		productsPreview = data.products as ProductPreviewGQL[]
	} catch (e) {
		productsPreviewError = (e as ApolloError).message
	}

	const blogPosts = await getPosts({
		filter: 'id: -5fc6382f95ca180038dc3fc4',
		include: 'tags,authors',
		limit: '3',
	})

	const featuredBlogPost = await getSinglePost('5fc6382f95ca180038dc3fc4')

	const props: IProps = {
		productsPreview,
		productsPreviewError,
		merchantsPreview,
		merchantsPreviewError,
		blogPosts,
		featuredBlogPost,
	}

	return addApolloState(apolloClient, { props })
}

export default Homepage
