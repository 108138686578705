import { Text4, Text5, Text6 } from '../Typography'
import ResponsiveImage from '../ResponsiveImage'
import ArrowLink from '../ArrowLink'
import { MerchantDesc } from '../../pages'
import { MerchantPreview } from '../../apis/generated/graphql'
import { Box } from '@mui/material'
import NextLink from 'next/link'

interface IProps {
	merchantsPreview: MerchantPreview[]
}

export const HomepageMerchants = ({ merchantsPreview }: IProps) => {
	return (
		<Box
			display="flex"
			width={[1, 1, 7 / 12, 10 / 12]}
			flexDirection={'column'}
			mx="auto"
			my={2}
			gap={2}
		>
			<Text4 fontWeight="semibold">Noen av våre produsenter</Text4>
			{merchantsPreview.map((activeMerchant, index) => {
				return (
					<Box display="flex" key={index} width={1} gap={3}>
						<Box width={[1, 1, 1, 4 / 12]}>
							{/* TODO: To replace with normal images*/}
							<ResponsiveImage
								width={304}
								height={218}
								path={`v1571054281/${activeMerchant.imgId}`}
							/>
						</Box>
						<Box pb="1" px="0" width={[1, 1, 1, 8 / 12]}>
							<Text4 mb={2} fontWeight="semibold">
								{activeMerchant.title}
							</Text4>
							<MerchantDesc mb={3} fontWeight="regular">
								{activeMerchant.description}
							</MerchantDesc>
							<NextLink
								href="/[produsent]"
								as={`/${activeMerchant.slug}-${activeMerchant.id}`}
								passHref
							>
								<ArrowLink my="2">
									<Text6>Se produsentens produkter</Text6>
								</ArrowLink>
							</NextLink>
						</Box>
					</Box>
				)
			})}
		</Box>
	)
}
