import React from 'react'
import theme from '../../styles/theme'
import { Text3 } from '../Typography'
import { ExternalLink } from '../Link/Link'
import { Box, Grid } from '@mui/material'

interface IProps {
	img: string
	title: string
	url: string
}

export const BlogArticle = ({ img, title, url }: IProps) => {
	return (
		<Grid
			container
			sx={{ borderRadius: 1, overflow: 'hidden', height: '100%' }}
			bgcolor={theme.colors.yellow50}
			gridAutoRows={'1fr'}
		>
			<Grid item width={[1, 1, 1, 1]} height={200}>
				<Box
					sx={{
						height: '100%',
						backgroundImage: `url(${img})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				/>
			</Grid>

			<Grid item width={[1, 1, 1, 1]} px={[2, 3, 4, 5]} py={2}>
				<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} pb={2}>
					<Text3 py={[3, 1, 2, 3]} textAlign="left" fontWeight="medium">
						{title}
					</Text3>
					<Box>
						<ExternalLink href={url} title={'Les mer'} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}
