import React from 'react'
import { Text4 } from '../Typography'
import { Box, Grid } from '@mui/material'
import { ExternalLink } from '../Link/Link'
import { BlogArticle } from '../Blog/BlogArticle'
import { BlogFeaturedArticle } from '../Blog/BlogFeaturedArticle'

interface IProps {
	blogPosts: any[]
	featuredBlogPost: any
}

export const HomepageBlog = ({ blogPosts, featuredBlogPost }: IProps) => {
	return (
		<Box>
			<Box display="flex" py={2} justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Text4 fontWeight="semibold">Om lokalmat</Text4>
				<ExternalLink href={'https://blogg.matfra.no'} title={'Se alle blogginnlegg'} />
			</Box>

			{/* Featured blog article */}
			<BlogFeaturedArticle
				url={featuredBlogPost.url}
				title={featuredBlogPost.title}
				img={featuredBlogPost.feature_image}
				excerpt={featuredBlogPost.excerpt}
			/>

			{/* Other blog articles */}
			<Grid container gridAutoFlow={'column'} spacing={2} mt={2}>
				{blogPosts.map((post, index) => {
					return (
						<Grid item key={index} width={[1, 6 / 12, 4 / 12, 4 / 12]}>
							<BlogArticle img={post.feature_image} title={post.title} url={post.url} />
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
}
